import { ClientEnvironment, ClientEnvironmentName } from '~/core/types/environment';

export const environment: ClientEnvironment = {
	environmentName: ClientEnvironmentName.REPLICA,

	server: {
		url: 'https://api.replica.rea.ch',
	},

	mainClient: {
		url: 'https://mobile.replica.rea.ch',
	},

	handbook: {
		url: 'https://handbook.replica.rea.ch',
	},

	myGenieServer: {
		url: 'https://chat.replica.rea.ch',
	},
};
