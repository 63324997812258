import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MarkdownService } from 'ngx-markdown';
import { SettingsReactorComponent } from '~/settings/settings.reactor.component';

@Component({
	selector: 'app-root',
	template: `<app-settings-reactor></app-settings-reactor><router-outlet></router-outlet>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterModule, SettingsReactorComponent],
	host: {
		class: 'block w-100 h-100',
	},
})
export class RootPageComponent {
	private readonly markdownService = inject(MarkdownService);

	constructor() {
		this.markdownService.renderer.link = (config) => {
			return `<a href="${config.href}" target="_blank">${config.text}</a>`;
		};
	}
}
